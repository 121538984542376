<template>
  <div class="relative h-full">
    <UiNav
      v-if="navigation?.length"
      v-model:active="active"
      class="hidden lg:flex"
      :links="navigation"
      :base-url="localePath()"
      @navigate="(link) => onNavigate(link)"
      @close="emit('close')"
    />
    <UiNavMobile
      v-if="navigation?.length"
      class="flex lg:hidden"
      :base-url="localePath()"
      :breadcrumbs="activeTabId === 'categoryTree' ? visibleBreadcrumbs : []"
      data-test="ui-nav-mobile"
      @click:breadcrumb="navigateToBreadcrumb"
      @close="onNavMobileClose"
    >
      <template
        v-if="navTabs.length > 1"
        #extra-header
      >
        <div
          class="w-full overflow-hidden bg-white text-blue"
        >
          <UiTabs
            v-model:active="activeTabId"
            :tabs="navTabs"
            class="font-bold"
            variant="menu"
          />
        </div>
      </template>
      <template
        v-if="shouldDisplaySearch"
        #search
      >
        <AppSearch
          ref="mobileAppSearch"
          v-model:search-phrase="searchPhrase"
          :show-suggestions-as-tooltip="false"
          fill-available
          @search="emit('close')"
        />
      </template>
      <div
        id="app-nav-teleport-area"
        ref="teleportAreaRef"
        class="flex flex-col overflow-y-auto"
        :class="[activeTabId !== 'categoryTree' ? 'h-full' : '']"
      />
      <div
        class="overflow-y-auto"
      >
        <UiTabsItems
          :items="navTabs"
          :active="activeTabId"
        >
          <template #categoryTree>
            <UiNavMobileCategoryTree
              v-model:active="active"
              v-model:breadcrumbs="breadcrumbs"
              :links="navigationCategories"
              :labels="{ allCategories: t('all') }"
              :base-url="localePath()"
              @navigate="(link) => onNavigate(link)"
            />
          </template>
        </UiTabsItems>
      </div>
      <div
        v-if="activeTabId === 'categoryTree' && breadcrumbs?.length === 0"
        class="flex flex-col gap-0.5 px-8"
      >
        <NuxtLink
          v-for="(link, index) in mainMenuLinks"
          :key="`menu-link-${index}`"
          :to="link.path"
          class="flex items-center gap-2.5 py-4 text-base font-bold text-blue"
          @click.prevent="() => onNavigate({ link })"
        >
          <UiIcon
            :name="link.icon"
            :width="24"
            :height="24"
          />
          {{ link.title }}
          <UiIcon
            name="arrow"
            class="ml-auto"
            :width="16"
            :height="16"
          />
        </NuxtLink>
      </div>
    </UiNavMobile>
  </div>
</template>

<script setup lang="ts">
import { hasProtocol } from 'ufo'
import UiTabsItems from '@ui/components/UiTabs/UiTabsItems/UiTabsItems.vue'
import UiNavMobile from '@ui/components/UiNav/UiNavMobile/UiNavMobile.vue'
import UiNav from '@ui/components/UiNav/UiNav.vue'
import UiTabs from '@ui/components/UiTabs/UiTabs.vue'
import { type RawlplugUiMq, RawlplugUiMqKey } from '@ui/plugin'
import { useAppSearchState } from '@ecom/components/AppSearch/useAppSearchState'
import { useDrawer } from '@base/components/AppDrawer/useDrawer'
import { type UiNavLink } from '@ui/components/UiNav/UiNav.types'
import AppSearch from '@ecom/components/AppSearch/AppSearch.vue'
import UiNavMobileCategoryTree from '@ui/components/UiNav/UiNavMobile/UiNavMobileCategoryTree/UiNavMobileCategoryTree.vue'
import UiIcon from '@ui/components/UiIcon/UiIcon.vue'
import useConditionalRender from '../../composables/useConditionalRender/useConditionalRender'
import type { AppNavLink } from './AppNav.types'
import { appNavTeleportArea, useAppNav } from './useAppNav'
import { useAppNavState } from './useAppNavState'

const props = withDefaults(
  defineProps<{
    parentLink?: string | number | boolean
    clickOrigin?: 'default' | 'category'
  }>(),
  {
    parentLink: false,
    clickOrigin: 'default',
  },
)

const emit = defineEmits<{
  (e: 'close'): void
}>()

const {
  activeTabId,
  activeTab,
  navTabs,
  customCategories,
  breadcrumbs,
  shouldBeVisible,
} = useAppNavState()

const { searchPhrase } = useAppSearchState()

const mobileAppSearch = ref<InstanceType<typeof AppSearch>>()

const shouldDisplaySearch = computed(() => {
  return !!activeTab.value?.showSearch && navTabs.value.length > 1
})

const visibleBreadcrumbs = computed(() => {
  const rootLink = { title: t('all_categories'), link: '/' }

  if (breadcrumbs.value.length) {
    return [rootLink, ...breadcrumbs.value.slice(0, -1)]
  }

  return undefined
})

function navigateToBreadcrumb(breadcrumb: UiNavLink) {
  const breadcrumbIndex = breadcrumbs.value.findIndex(b => b.link === breadcrumb.link)

  if (breadcrumbIndex === -1) {
    breadcrumbs.value = []
    return
  }

  breadcrumbs.value.splice(breadcrumbIndex + 1)
}

const { runWithContext } = useNuxtApp()
const { t } = useI18n()
const { localePath } = useT3Utils()
const active = ref<AppNavLink[]>([])
const { navigation } = useAppNav()
const { isMobile } = (inject(RawlplugUiMqKey) as RawlplugUiMq) || {}
const router = useRouter()
const { toggleDrawer } = useDrawer()

const { listen } = router.options.history

const navigationCategories = computed(() => {
  if (customCategories.value?.length) { return customCategories.value }
  return navigation.value
})

watch(() => props.parentLink, () => {
  if (props.parentLink && typeof props.parentLink === 'string') {
    breadcrumbs.value = []
    active.value = []
    updateNavigationByParent(props.parentLink)
  }
})

watch(searchPhrase, () => {
  if (!searchPhrase.value?.length && mobileAppSearch.value) {
    mobileAppSearch.value.areSearchSuggestionsVisible = false
  }
})

function updateNavigationByParent(parentLink: string) {
  const parentLinkData = navigation.value.find(
    ({ link }) => link === parentLink,
  )

  if (!parentLinkData) { return }
  active.value.push(parentLinkData)
}

function isExternal(link: string) {
  return link === '' || hasProtocol(link, { acceptRelative: true })
}

function onNavigate({ link }: AppNavLink) {
  const linkValue = isRef(link) ? link.value : link
  if (!isExternal(linkValue)) {
    navigateTo(linkValue)
  }
  else {
    navigateTo(linkValue, {
      external: true,
      open: {
        target: '_blank',
      },
    })
  }
  shouldBeVisible.value = true
  emit('close')
}

const isLoggedInCustomer = ref(false)
const { isEcommerceEnabled } = useConditionalRender()
if (isEcommerceEnabled.value) {
  await import('../../../rawlplug-customer/composables/useCustomer').then(({ useCustomer }) => {
    runWithContext(() => {
      const { isLoggedIn } = useCustomer()
      isLoggedInCustomer.value = isLoggedIn.value
    })
  })
}

const mainMenuLinks = computed(() => {
  const entries = [
    { icon: 'list', title: t('shopping_lists'), path: localePath('/account/lists'), visible: isLoggedInCustomer.value },
    // { icon: 'heart', title: t('favourites'), path: localePath('/'), visible: isLoggedIn.value },
    // { icon: 'help', title: t('help_center'), path: localePath('/') },
  ]

  return entries.filter(link => typeof link.visible === 'undefined' || link.visible)
})

function onNavMobileClose() {
  shouldBeVisible.value = false
  emit('close')
}

const teleportAreaRef = ref<HTMLDivElement>()
watch(teleportAreaRef, (value) => {
  appNavTeleportArea().value = value
}, { immediate: true })

onMounted(() => {
  listen((to, from, info) => {
    if (info.direction === 'back' && shouldBeVisible.value && isMobile.value) {
      toggleDrawer({
        open: shouldBeVisible.value,
        component: 'AppNav',
        position: 'bottom',
        key: '',
        data: {
          parentLink: '',
          clickOrigin: 'default',
        },
        props: {
          variant: 'blank',
        },
      })
    }
  })
})
</script>
